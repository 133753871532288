import {
    AdvoxSlider as AdvoxBasicAdvoxSlider,
    NextArrow,
    PrevArrow,
} from 'AdvoxBasicComponent/AdvoxSlider/AdvoxSlider.component';

import './AdvoxSlider.override.style';

export { NextArrow, PrevArrow };

/** @namespace AdvoxStrigob2c/Component/AdvoxSlider/Component/AdvoxSlider */
export class AdvoxSlider extends AdvoxBasicAdvoxSlider {
    // TODO implement logic
}

export default AdvoxSlider;
